var site = site || {};

(function ($) {
  Drupal.behaviors.utilityMenuV1 = {
    attach: function (context) {
      var $mobileBackButton = $('.js-mobile-checkout-back', context);

      $mobileBackButton.once().on('click', function (e) {
        e.preventDefault();
        history.go(-1);
      });

      var $utilDropdownLinks = $('.site-utils__item--dropdown', context);
      var $utilDropdownClose = $('.site-utils__dropdown__close', context);
      var $navLinks = $('.menu__link--lvl-1, .menu__link--lvl-2', context);

      // Utility Dropdowns
      if (!Modernizr.touch) {
        // Hover (desktop)
        var selectBoxFlag = false;

        $utilDropdownLinks
          .on('mouseover', function () {
            $(this).not('.active').addClass('active').trigger('changedActiveState');
          })
          .on('mouseout', function (e) {
            // Prevented popup from hiding on hovering the autofill
            if ($(e.target).is('input')) {
              return false;
            }
            $('.selectBox-options').each(function () {
              if ($(this).is('.selectBox-options-bottom , .selectBox-options-top')) {
                selectBoxFlag = true;

                return false;
              } else {
                selectBoxFlag = false;
              }
            });
            if (selectBoxFlag === false) {
              $(this).filter('.active').removeClass('active').trigger('changedActiveState');
            }
          });

        var $target = $('.js-site-utils__item--dropdown a.js-site_utils', context);

        $target.keydown(function (e) {
          var $this = $(this);

          if (site.getKeycode(e) === 13) {
            e.preventDefault();
            var $utilLinks = $this.parents('.js-site-utils__item--dropdown');

            $this.first().attr('aria-expanded', 'true');
            $utilLinks.trigger('mouseover');
            $('.js-site-utils-dropdown-content').focus();
          }
          if (e.shiftKey) {
            $this.parent().removeClass('active');
            $this.first().attr('aria-expanded', 'false');
          }
        });
      } else {
        // Click (for touch devices)
        $('.site-utils__link', $utilDropdownLinks).on('click', function (event) {
          var $dropDown = $(this).parents('.site-utils__item--dropdown');

          if (Unison.fetch.now().name !== 'small' && $dropDown.has('form')) {
            return;
          }

          // Do nothing if clicking on the close button
          if ($(event.target).closest('.site-utils__dropdown__close').length) {
            return;
          }

          if (!$dropDown.hasClass('active')) {
            $utilDropdownLinks.filter('.active').removeClass('active').trigger('changedActiveState');
          }

          $dropDown.toggleClass('active').trigger('changedActiveState');

          event.preventDefault();
        });

        // Close util dropdowns when clicking outside of them:
        $('body').on('touchstart click', function (event) {
          if (!$(event.target).parents('.site-utils__item--dropdown').length) {
            $utilDropdownLinks.filter('.active').removeClass('active').trigger('changedActiveState');
          }
        });
      }

      $utilDropdownClose.on('touchstart click keydown', function (e) {
        var keyCode = site.getKeycode(e);
        var $this = $(this);

        if (keyCode === 1 || keyCode === 13) {
          e.preventDefault();
          $this.closest('li').find('.js-site_utils').attr('aria-expanded', 'false').focus();
          var $closestDropdown = $this.closest('.js-site-utils__item--dropdown');

          $closestDropdown.removeClass('active').trigger('changedActiveState');
        }
      });

      $navLinks.focus(function () {
        var currObj = $(this);

        currObj.trigger('hover');
        if (currObj.hasClass('menu__link--has-children')) {
          currObj.attr('aria-expanded', 'false');
        }
      });

      $navLinks.hover(
        function () {
          $(document).trigger('main_navigation.item.hover.over', $(this));
        },
        function () {
          $(document).trigger('main_navigation.item.hover.off', $(this));
        }
      );
    }
  };
})(jQuery);
